L<template>
  <v-app :style="{ backgroundColor }">
    <v-container fill-height fluid>
      <v-row align="center"
        justify="center">
        <v-col>
          <div class="text-center white--text">
            <v-progress-circular indeterminate></v-progress-circular>
            <v-spacer></v-spacer>
            Signing out, please wait...
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import { themeColors } from '@/services/theme'
  import { logout } from '@/services/http/auth'
  import router from '@/router'

  export default {
    data() {
      return {
        backgroundColor: '#000'
      }
    },
    mounted() {
      themeColors().then(colors => {
        this.backgroundColor = colors.primary
      })
    },
    created: async function () {
      try {
        await logout()
        router.push({name: 'Login'})
      } catch(err) {
        err.response = err.response || {}
        if (err.response.status != 401) {
          window.alert('Something went wrong', 'Error', 'error')
          router.push({name: 'Home'})
        } else
          router.push({name: 'Login'})
      }
    }
  }
</script>

